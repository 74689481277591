<template>
    <Header :isShow="isShow" />
    <div class="wraper">
        <div class="content_box" ref="header">
            <div class="content max_small_box">
                <router-link class="list" v-for="(item, index) in detail" :key="index" :to="`/about/case_detail/${item.id}`">
                    <div class="img">
                        <img :src="imgUrl + item.image" alt="" />
                    </div>
                    <div class="text">
                        <p class="title">{{item.name}}</p>
                        <!-- <div class="desc" v-html="item.intro"></div> -->
                        <p class="desc" v-for="(list, index) in item.related" :key="index">{{list.title}}{{list.desc}}</p>
                    </div>
                </router-link>
                <div class="pagination max_small_box" v-if="total_page">
                    <a-pagination v-model:current="page" :total="total_page" @change="handleChangePage" />
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理函数
const detailEffect = () => {
    //详情
    const detail = ref()

     //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    //获取详情
    const getDetail = async (id, page_id) => {
        let postData = {
            page: 1
        }
        await post('/about/get_about_case', postData).then((res) => {
            // console.log('经典案例', res)
            if(res.code == 1) {
                detail.value = res.data.data,
                total_page.value = res.data.total
            }
        })
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        getDetail()
    }

    return { detail, getDetail, page, total_page, handleChangePage }
}

export default {
    name: 'Case',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理函数
        const { detail, getDetail, page, total_page, handleChangePage } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        const  route = useRoute()

        getDetail(route.params.id, route.params.page_id)

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, page, total_page, handleChangePage }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 50px;
}
.content{
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .list{
        display: flex;
        margin-bottom: 50px;
        cursor: pointer;
        @media screen and (max-width: 900px){
            display: block;
        }
        &:hover{
            .img{
                img{
                    transform: scale(1.1);
                }
            }
        }
        .img{
            width: 20%;
            @media screen and (max-width: 900px){
                width: 100%;
            }
            img{
                width: 100%;
                object-fit: cover;
                transition: .3s all;
            }
        }
        .text{
            width: 65%;
            margin-left: 5%;
            @media screen and (max-width: 900px){
                width: 100%;
                margin-left: 0%;
            }
            .title{
                color: rgba(47, 80, 158, 100);
                font-size: 20px;
                font-weight: 600;
                margin-top: 15px;
                margin-bottom: 30px;
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 10px;
                line-height: 20px;
            }
            .name{
                color: rgba(16, 16, 16, 100);
                font-size: 14px;
                margin-top: 20px;
                @media screen and (max-width: 900px){
                    line-height: 20px;
                }
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
    display: block;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>